
<template>
  <van-popup v-model="showAgeVerify">
    <div class="age-verify-container">
      <van-image class="brand-image" fit="contain" :src="brandImage"/>
      <span class="title">PLEASE VERIFY YOUR AGE</span>
      <span class="tips">You must be at least 21 years old to use this app. Are you confirming that you are 21 years of age or older?</span>
      <van-button class="confirm" @click="onConfirmAgeVerify" text="I am 21+"/>
      <van-button class="cancel" @click="onCloseVerifiy"  text="Not yet"/>
    </div>
  </van-popup>
</template>
<script>
export default {
  name: "VerifyAge",
  props: {
    showAgeVerify: {
      type: Boolean,
      default: true
    },
    brandImage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    onConfirmAgeVerify() {
      this.$emit('confirm')
    },
    onCloseVerifiy() {
      this.$emit('close')
    }
  }
};
</script>

<style scoped lang="less">

.age-verify-container {
  padding:15px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .brand-image {
    width: 150px;
    height: auto;;
  }
  .title {
    margin-top:20px;
    font-weight: bold;
    color:#6D6E71;
    font-size: 21px;
  }
  .tips {
    font-size: 18px;
    margin-top:10px
  }
  .confirm {
    margin-top: 40px;
    width: 100%;
    background: var(--themeMainColor);
    color:white;
    border-radius: 6px;
  }
  .cancel {
    margin-top: 10px;
    width: 100%;
    background: var(--cancelButtonColor);
    color:#939598;
    border-radius: 6px;
  }
}
</style>