<!--
 * @Author: Code-HHX
 * @Date: 2022-04-12 15:10:41
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-05-11 14:30:01
 * @Description: 首页/登录页面
-->
<template>
  <div class="container">
    <div class="header">
      <img class="logo-omni" :src="brandImage" />
    </div>
    <div class="content">
      <input
        ref="email"
        class="email"
        type="text"
        v-model.trim="email"
        placeholder="Email address"
        @focus="onFocusEmail"
      />
      <div class="password">
        <input
          type="password"
          ref="password"
          v-model="password"
          placeholder="Password"
        />
        <img
          v-if="passwordVisible"
          class="fa fa-eye-slash"
          src="@/assets/icons/icon_eye.png"
          @click="onClickEye"
        />
        <img
          v-else
          class="fa-visible fa-eye-slash"
          src="@/assets/icons/icon_eye_visible.png"
          @click="onClickEye"
        />
      </div>
      <div v-show="passwordError && errorCode === 514" class="errormsg">
        Password incorrect. If you don't remember your password please.
        <span @click="onClickResetIt">reset it.</span>
      </div>
      <div v-show="passwordError && errorCode === 518" class="errormsg">
        Invalid e-mail.
      </div>
      <div v-show="passwordError && errorCode === 556" class="errormsg">
        This account has been disabled.
      </div>


      <div class="privacy-policy">

        <van-checkbox v-model="checkedPrivacyPolicy" shape="square" :checked-color="themeMainColor">
          I agree to the <span class="policy-item" @click="goPrivacyPolicy">Privacy Policy</span> , <span
            class="policy-item" @click="goUserCondition">Terms and Conditions</span>
        </van-checkbox>
      </div>


      <div class="operate">
        <button class="button-login" @click="onClickLogin">Login</button>
        <div class="create" @click="onClickCreate">Create Account</div>
        <div class="forgot" @click="onClickForgot">Forgot Password?</div>
      </div>
    </div>
    <div class="footer">
      Powered by OMNI
    </div>
    <VerifyAge :show-age-verify="showAgeVerify" :brand-image="brandImage" @confirm="onConfirmAgeVerify" @close="onCloseAgeVerify"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import log from "@/util/log";
import { TOKEN } from "@/config/http-json";
import VerifyAge from "@/components/VerifyAge.vue";

export default {
  name: "Login",
  components: { VerifyAge },
  data() {
    return {
      email: "",
      password: "",
      // email: "ikrusher",
      // password: "123456",
      errorCode: "", //错误码
      passwordError: false,
      passwordVisible: false,
      checkedPrivacyPolicy: true,
      showAgeVerify: !localStorage.getItem('verify_age')
    };
  },
  created() {
    if (process.env.NODE_ENV === "dev") {
      this.email = "votest@qq.com";
      // this.email = "brand@ikrusher.com";
      // this.password = "brand123456";
      // this.email = "test@plugplay.com";
      // this.email = "test@trulieve.com";
      this.password = "123456";
    }
    let token = window.localStorage.getItem(TOKEN);
    if (token) {
      // if (navigator.nfcReader) {
      //   this.$router.replace("Function");
      // }else {
      //   this.$router.replace("NewHome");
      // }
      this.$router.replace({name:"Connect",query: {hash: this.$store.state.hash}});
    }
  },
  computed: {

    ...mapState("theme", ["themeMainColor"]),
    ...mapState(["brandImage"])
  },
  methods: {
    ...mapActions(["_setLoginInfo"]),
    onConfirmAgeVerify() {
      localStorage.setItem("verify_age", "verify");
      this.showAgeVerify = false
    },
    onCloseAgeVerify() {
      this.showAgeVerify = false
      this.$nextTick(()=> {
        this.showAgeVerify = true
      })
    },
    goPrivacyPolicy() {
      this.$router.push({
        name: "PrivacyPolicy",
        params: {
          title: "Privacy Policy",
          type: 1
        },query:  {hash: this.$store.state.hash}
      });
    },
    goUserCondition() {
      this.$router.push({
        name: "PrivacyPolicy",
        params: {
          title: "Terms and Conditions",
          type: 2
        },query:  {hash: this.$store.state.hash}

      });
    },
    onClickEye() {
      this.passwordVisible = !this.passwordVisible;
      if (this.$refs.password.type == "password") {
        this.$refs.password.type = "text";
      } else {
        this.$refs.password.type = "password";
      }
    },
    onClickLogin() {
      //this.passwordError = true;
      // this.$router.push({
      //   name: "home"
      // });

      //校验
      let checkStatus = true;
      if (this.$utils.isNullAndEmpty(this.email)) {
        this.$refs.email.style.background = "#f4e6e9";
        this.$refs.email.style.border = "1px solid #e08cae";
        checkStatus = false;
      } else {
        this.$refs.email.style.background = "#e8eff2";
        this.$refs.email.style.border = "none";
      }
      if (this.$utils.isNullAndEmpty(this.password)) {
        this.$refs.password.style.background = "#f4e6e9";
        this.$refs.password.style.border = "1px solid #e08cae";
        checkStatus = false;
      } else {
        this.$refs.password.style.background = "#e8eff2";
        this.$refs.password.style.border = "none";
      }
      if (!checkStatus) return;

      this.$api.User.doLogin({
        userName: this.email,
        password: this.password
      })
        .then(res => {
          log(res);
          if (res.code === 200) {
            window.localStorage.setItem(TOKEN, res.data.token);
            window.localStorage.setItem("omni-user", JSON.stringify(res.data));
            this._setLoginInfo(res.data);
            // this.$store.dispatch("fetchLocalDisposableVoltage");
            // this.$store.dispatch("fetchLocalBatteryVoltage");
            this.$router.replace({name:"Connect",query: {hash: this.$store.state.hash}});
          } else if (res.code === 514 || res.code === 518 || res.code === 556) {
            this.errorCode = res.code;
            this.passwordError = true;
            window.localStorage.setItem(TOKEN, "");
            this._setLoginInfo(null);

            if (res.code === 518 || res.code === 556) {
              this.$refs.email.style.background = "#f4e6e9";
              this.$refs.email.style.border = "1px solid #e08cae";
              checkStatus = false;
            } else if (res.code === 514) {
              this.$refs.password.style.background = "#f4e6e9";
              this.$refs.password.style.border = "1px solid #e08cae";
              checkStatus = false;
            }
          } else {
            window.localStorage.setItem("omni-token", "");
            this._setLoginInfo(null);
            this.$toast(res.message);
          }
        })
        .catch(error => {
          window.localStorage.setItem("omni-token", "");
          this._setLoginInfo(null);
          this.$toast(error);
        });
    },
    onClickResetIt() {
      this.$refs.password.style.background = "#e8eff2";
      this.$refs.password.style.border = "none";
      this.password = "";
      this.errorCode = "";
      this.passwordError = false;
    },
    onClickCreate() {
      this.$router.push({name:"Register",query:  {hash: this.$store.state.hash}});
    },
    onClickForgot() {
      this.$router.push({name:"Retrieve",query:  {hash: this.$store.state.hash}});
    },
    onFocusEmail() {
      this.$refs.email.style.background = "#e8eff2";
      this.$refs.email.style.border = "none";
      this.errorCode = "";
    }
  }
};
</script>

<style lang="less" scoped>

.container {
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .logo-omni {
      margin: 64px 0 29px 0;
    }

    .by {
      font-size: 18px;
      font-weight: normal;
      color: #bcbec0;
    }

    .logo-ikrusher {
      margin: 20px 0 75px 0;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 0 25px;

    .email {
      border: 1px solid transparent;
    }

    .password {
      margin-top: 9px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      input {
        border: 1px solid transparent;
      }

      .password-input {
        width: 90% !important;
      }

      .fa {
        position: absolute;
        right: 40px;
        width: 31px;
        height: 20px;
      }

      .fa-visible {
        position: absolute;
        right: 40px;
        width: 32px;
        height: 29px;
      }
    }

    .emptyError {
      background: #f4e6e9 !important;
      border: 1px solid #e08cae !important;
    }

    .errormsg {
      margin-top: 9px;
      font-size: 16px;
      font-weight: normal;
      color: #d66384;

      span {
        text-decoration: underline;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-user-select: none;
        -moz-user-focus: none;
        -moz-user-select: none;
      }
    }

    .operate {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .button-login {
      width: 70%;
      margin-top: 28px;
      transition: opacity 0.2s;
      -webkit-appearance: none;
      background: var(--themeMainColor);
    }
    .button-login:active {
      opacity: 0.8;
      -webkit-tap-highlight-color: transparent;
    }

    .forgot {
      margin-top: 19px;
      font-size: 18px;
      font-weight: normal;
      color: #231f20;
    }
    .create {
      margin-top: 35px;
      font-size: 18px;
      font-weight: normal;
      color: #231f20;
    }
  }
  .footer {
    width: 100%;
    text-align: center;
    position: fixed;
    font-size: 18px;
    color: #BCBDBF;
    bottom: 50px;
  }
}

.privacy-policy {
  margin-top: 20px;
  font-size: 14px;

  .policy-item {
    color: var(--themeMainColor);
  }
}

::v-deep .van-checkbox {
  align-items: flex-start;
}

</style>
