<template>
  <div class="container">
    <div class="header">
      <van-icon
          class="header-return"
          name="arrow-left"
          size="25"
          color="#bcbec0"
          @click="onClickHeaderReturn()"
      />
      <van-icon
          class="header-exit"
          :name="require('@/assets/icons/icon_settings_exit.png')"
          size="30"
          @click="onClickSignOut"
      />
    </div>
    <div class="profile">
      <div class="avatar-content">
        <button class="button"></button>
        <van-image class="avatar" :src="userAvatar" round width="110" height="110" @click="selectImage"/>
      </div>
      <span class="username">{{ loginInfo.username }}</span>
    </div>

    <div class="menu-items">
      <van-cell style="padding-top:15px;padding-bottom:15px;" v-for="(item,index) in menuItems" :key="index" is-link @click="item.func">
        <template #icon>
          <van-image class="icon" :src="item.icon"/>
        </template>
        <template #title>
          <span class="title">{{ item.title }}</span>
        </template>
      </van-cell>
    </div>


    <!-- Account Popup -->
    <van-popup
        class="account-popup"
        v-model="showAccountPopup"
        round
        :close-on-click-overlay="false"
    >
      <div class="popup-header">
        Account
        <img src="@/assets/icons/icon_close.png" @click="onClickClose"/>
      </div>
      <div class="popup-content">
        <div class="title">Email Address</div>
        <div class="info">
          {{ loginInfo.username }}
          <!--<img src="@/assets/icons/icon_edit.png" alt="" @click="onClickEdit" />-->
        </div>
        <div class="title">Password</div>
        <div class="info">
          ●●●●●●●●
          <!--<img src="@/assets/icons/icon_edit.png" alt="" @click="onClickEdit" />-->
        </div>
      </div>
      <div class="popup-button" @click="onClickSignOut">Sign Out</div>
    </van-popup>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import login from "./Login.vue";
import bluetoothRepository from "@/bluetooth/BluetoothRepository";
import { TOKEN } from "@/config/http-json";
import bus from "@/util/EventBus";
import { Toast } from "vant";
import user from "@/api/user";

export default {
  name: "AppSettings",
  data() {
    return {
      showAccountPopup: false,
      userAvatar : null
    };
  },
  methods: {
    ...mapActions(["_setLoginInfo"]),
    onClickHeaderReturn() {
      this.$router.go(-1);
    },

    onClickClose() {
      this.showAccountPopup = false;
    },
    onClickSignOut() {
      this.$dialog
          .confirm({
            title: "Tips",
            message:
                "Are you sure you want to exit the currently logged in account?",
            confirmButtonText: "Yes",
            cancelButtonText: "No"
          })
          .then(res => {
            bluetoothRepository.disconnect();
            window.localStorage.setItem(TOKEN, "");
            window.localStorage.setItem("omni-user", null);
            this._setLoginInfo(null);
            this.showAccountPopup = false;
            this.$router.replace({name:"Login",query: {hash:this.$store.state.hash}});
          })
          .catch(() => {
          });
    },
    async onClickReset() {
      console.log(bus._events)
      this.$dialog
          .confirm({
            title: "Tips",
            message:
                "Reset App back to default?",
            confirmButtonText: "Yes",
            cancelButtonText: "No"
          })
          .then(async res => {
            await this.$store.dispatch("resetApp");
            if (bus._events.resetAppNFC) {
              bus.$emit("resetAppNFC", false, () => {
                if (bus._events.resetAppBt) {
                  bus.$emit("resetAppBt", false, () => {
                    Toast.success("Reset success!");
                  }, () => {
                    Toast.success("Reset fail!");
                  });
                }else {
                  Toast.success("Reset success!");
                }
              }, () => {
                Toast.success("Reset fail!");
              });
            }else if (bus._events.resetAppBt) {
              bus.$emit("resetAppBt", false, () => {
                if (bus._events.resetAppNFC) {
                  bus.$emit("resetAppNFC", false, () => {
                    Toast.success("Reset success!");
                  }, () => {
                    Toast.success("Reset fail!");
                  });
                }else {
                  Toast.success("Reset success!");
                }
              }, () => {
                Toast.success("Reset fail!");
              });

            }


          })
          .catch(() => {
          });
    },
    onClickClearCache() {
      this.$dialog
          .confirm({
            title: "Tips",
            message:
                "Clear cache that may be exit the currently logged in account and reset app",
            confirmButtonText: "Yes",
            cancelButtonText: "No"
          })
          .then(res => {
            window.clearAppCache([
              "WKWebsiteDataTypeDiskCache",
              "WKWebsiteDataTypeMemoryCache",
              "WKWebsiteDataTypeOfflineWebApplicationCache",
              "WKWebsiteDataTypeCookies"
            ]);
          });
    },
    async onClickVersion() {
      this.$dialog
          .alert({
            title: "App Version",
            message: "2.0.0",
            confirmButtonText: 'Close'
          })
    },
    async onClickAbout() {
      this.$dialog
          .alert({
            title: "About",
            message: "Omni App",
            confirmButtonText: 'Close'
          })
    },
    selectImage() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange =  async e => {
        const file = e.target.files[0];
        if (file) {
          const selectedImage = URL.createObjectURL(file);
          try {

            const formData = new FormData();
            formData.append('file', file);
            Toast.loading('Uploading')
            const resp = await user.uploadFile(formData);
            console.log(resp)
            if (resp.code !== 200) {
              Toast.fail(resp.message);
            }else {
              const avatarUrl = resp.data;
              if (resp.code !== 200) {
                Toast.fail(resp.message);
              }else {
                await user.updateUserProfile({ picture: avatarUrl})
                const userInfo = { ...this.loginInfo }
                userInfo.picture= avatarUrl
                this._setLoginInfo(userInfo)
                this.userAvatar = selectedImage
              }

            }

          } catch (err) {
            Toast.fail('Upload avatar fail!' + err)
          }
        }
      };
      input.click();
    }
  },
  computed: {
    ...mapState(["loginInfo"]),
    menuItems() {
      const defaultItems = [
        { icon: require("@/assets/icons/icon_settings_reset.png"), title: "Reset", func: this.onClickReset },
        {
          icon: require("@/assets/icons/icon_settings_clear_cache.png"),
          title: "Clear Cache",
          func: this.onClickClearCache
        },
        // { icon: require("@/assets/icons/icon_settings_about.png"), title: "About", func:this.onClickAbout},
        { icon: require("@/assets/icons/icon_settings_version.png"), title: "Version",func:this.onClickVersion }
      ];
      if (!window.clearAppCache) {
        defaultItems.splice(1, 1);
      }
      return defaultItems;
    }
  },
  mounted() {
    this.userAvatar = this.loginInfo.picture
  }
};
</script>

<style scoped lang="less">
.container {
  height: 100%;
  background: #FFFFFF;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 60px;
    min-height: 60px;
    background: #f1f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #545554;

    .header-return {
      position: absolute;
      left: 10px;
    }

    .header-exit {
      position: absolute;
      right: 10px;
    }
  }

  .profile {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F1F2F2;
    justify-content: center;

    .avatar-content {
      position: relative;
      display: inline-block;
      width:110px;
      height:110px;

      .button {
        position: absolute;
        top: 50px;
        left: 50px;
        z-index: 0;

      }
      .avatar {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

    }
    .username {
      font-size: 16px;
      margin-top: 12px;
      color: #939598;
    }
  }

  .menu-items {
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    .title {
      font-size: 15px;
      font-weight: bold;
      color: #555555;
    }
  }
}


.account-popup {
  width: 90%;

  .popup-header {
    font-size: 21px;
    font-weight: normal;
    color: #6d6e71;
    display: flex;
    align-items: center;
    padding: 20px 20px 48px 28px;

    img {
      margin-left: auto;
      width: 28px;
      height: 28px;
    }
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 28px;

    .title {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: normal;
      color: #a7a9ac;
    }

    .info {
      font-size: 18px;
      font-weight: normal;
      color: #808285;
      display: flex;
      align-items: center;
      margin-bottom: 33px;

      img {
        margin-left: auto;
        margin-right: 3px;
        width: 19px;
        height: 19px;
      }
    }
  }

  .popup-button {
    margin: 62px 25px 27px 25px;
    height: 45px;
    line-height: 45px;
    background: var(--themeMainColor);
    border-radius: 8px;
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    color: #ffffff;
    transition: opacity 0.2s;
    -webkit-appearance: none;
  }

  .popup-button:active {
    opacity: 0.8;
    -webkit-tap-highlight-color: transparent;
  }
}
</style>